var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Importação/Exportação de Dados")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Área reservada à importação e/ou exportação de informação relativa a colaborações/equipas de trabalho, de documentação de apoio e técnica/científica e também de notícias sobre a plataforma. ")]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","justify":"center","align":"center"}},[_c('div',{staticClass:"clav-info-content"},[_c('v-select',{staticClass:"mt-n1 mb-2 px-8",attrs:{"items":_vm.selectDados,"item-text":"label","item-value":"links","label":"Selecione o tipo de dados pretendido","hide-details":"","single-line":""},model:{value:(_vm.opcao),callback:function ($$v) {_vm.opcao=$$v},expression:"opcao"}})],1)])],1),_c('v-snackbar',{attrs:{"timeout":2000,"color":"error","top":"true"},model:{value:(_vm.snackbarError),callback:function ($$v) {_vm.snackbarError=$$v},expression:"snackbarError"}},[_vm._v(" Nenhuma opção se encontra selecionada. "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":_vm.fecharSnackbar}},[_c('unicon',{attrs:{"name":"remove-icon","width":"15","height":"15","viewBox":"0 0 20.71 20.697","fill":"#ffffff"}})],1)],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[(
          [4, 5, 6, 7].includes(this.$userLevel()) &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.go(("" + (_vm.opcao.importar)))}}},[_c('unicon',{attrs:{"name":"importar-icon","width":"20","height":"20","viewBox":"0 0 20.71 18.121","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Importar")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(
          this.$userLevel() === 7 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.exportarFicheiro(("" + (_vm.opcao.exportar)))}}},[_c('unicon',{attrs:{"name":"exportar-icon","width":"20","height":"20","viewBox":"0 0 20.71 18.001","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Exportar")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }